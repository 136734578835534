<template>
  <b-modal
    id="project-customer-modal"
    :visible="shallShowModal"
    title="线索客户"
    body-class="px-2"
    hide-footer
    size="lg"
    centered
    no-close-on-backdrop
    static
    @change="shallShowModal = !shallShowModal"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 线索客户 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>
    <!-- Modal: Body -->
    <!-- <div class="d-flex justify-content-end"><b-button variant="primary"> 导出 </b-button></div> -->
    <vxe-table
        ref="xTable"
        class="mt-50"
        border
        :auto-resize="true"
        :data="tableData"
        highlight-current-row
        :loading="loading"
        header-row-class-name="false"
        :scroll-x="{enabled: true}"
        :scroll-y="{enabled: true}"
      >
        <vxe-table-column field="id" title="ID" width="80"></vxe-table-column>
        <vxe-table-column field="nickname" title="用户昵称" min-width="180"></vxe-table-column>
        <vxe-table-column field="avatar" title="用户头像" width="100" align="center">
          <template v-slot="{ row }">
            <b-avatar size="md" :src="row.avatar" />
          </template>
        </vxe-table-column>
        <vxe-table-column field="phone" title="电话号码" width="180"></vxe-table-column>
      </vxe-table>

      <div class="d-flex justify-content-between flex-wrap mb-1">
        <div class="d-flex align-items-center mb-0 mt-1">
          <Select v-model="searchParams.size" class="mr-1">
            <Option v-for="item in pageOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <span class="text-nowrap"> 共 {{ total }} 条 </span>
        </div>
        <div class="d-flex align-items-center">
          <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <div class="d-flex align-items-center mb-0 mt-1 ml-2">
            <b-button variant="primary"> 导出 </b-button>
          </div>
        </div>
      </div>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { reportListStaffInfo } from '@/api/report/report.js'
// Notification
import { VBTooltip } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    vSelect,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    staffId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    // const { emit } = context.root

    // 消息提醒组件
    const toast = useToast()

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // table 控制加载
    const loading = ref(false)
    // table 数据源
    const tableData = ref([])
    // 列表记录 记录总数
    const total = ref(0)
    // 查询条件
    const searchParams = ref({
      page: 1, // 分页
      size: 10, // 每页多少记录
    })
    // 页面跳转到
    const pageTo = ref(null)
    // 分页查询选择项
    const pageOptions = ref([
      { label: '10 条/页', value: 10 },
      { label: '20 条/页', value: 20 },
    ])
    // 处理页面跳转到 用户输入, 判断数据是否介入 1 ~ 最大数
    const pageToChange = () => {
      const count = Math.ceil(total.value / searchParams.value.size)
      if (pageTo.value < 1) {
        pageTo.value = 1
      } else if (pageTo.value > count) {
        pageTo.value = count
      } else {
        pageTo.value = parseInt(pageTo.value, 0)
      }
      searchParams.value.page = pageTo.value
    }

    // vxe-table DOM 对象
    const xTable = ref(null)


    /* 接口调用区域 */

    // 获取列表数据
    const fetchData = () => {
      reportListStaffInfo({
        page: searchParams.value.page,
        size: searchParams.value.size,
        projectId: props.projectId,
        staffId: props.staffId,
        isExport: false,
      })
        .then(res => {
          tableData.value = res.data.list
          total.value = res.data.total
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取客户信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 查询条件变更时 => 发起请求
    watch(searchParams.value, () => { fetchData() })

    watch(() => props.shallShowModal, () => {
      if (props.shallShowModal) { fetchData() }
    })

    return {
      loading,
      tableData,
      total,

      pageOptions,
      searchParams,
      pageTo,
      pageToChange,

      xTable,
      fetchData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
